'use client';
import cn from 'classnames';
import React from 'react';

import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import NoDownloadImage from '../no-download-image/NoDownloadImage';

import Styles from './ThankYou.module.scss';

type Props = {
  submittedTitle?: string | null;
  submittedParagraph?: string | null;
  submittedImage?: string | null;
  filesMeta?: FilesMeta;
  cardStyle?: boolean;
};
const ThankYou = (props: Props) => {
  const {
    submittedTitle,
    submittedParagraph,
    submittedImage,
    filesMeta,
    cardStyle = true,
  } = props;

  return (
    <div
      className={cn(
        Styles.thankYouWrapper,
        !cardStyle && Styles.thankYouWrapperWithoutCard,
      )}
    >
      {submittedImage && (
        <NoDownloadImage
          src={getDirectusAssetURI(submittedImage) ?? ''}
          alt={
            filesMeta
              ? getAltProperty(
                  filesMeta,
                  submittedImage ?? '',
                  'Form submitted icon',
                )
              : 'Form submitted icon'
          }
          height={160}
          width={160}
          className={Styles.thankYouImage}
        />
      )}
      <div className={Styles.thankYouContent}>
        <div className="headingElement">
          {submittedTitle && <h2 className="titleLg"> {submittedTitle}</h2>}
        </div>
        {submittedParagraph && (
          <div
            dangerouslySetInnerHTML={{
              __html: submittedParagraph,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ThankYou;
