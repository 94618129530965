'use client';

import Image, { ImageProps } from 'next/image';
import React from 'react';

const NoDownloadImage: React.FC<ImageProps> = ({ alt, ...props }) => {
  return (
    <Image
      {...props}
      alt={alt || 'Image'}
      onContextMenu={(e) => e.preventDefault()}
      draggable={false}
    />
  );
};

export default NoDownloadImage;
